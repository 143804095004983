<template>
    <div class="row-item">
        <div class="column-space-log" v-if="extraInfo.includes('space-log')"></div>
        <div class="column-icon" v-if="task.type === 'task' && task.frequency != null"><icon-repeat /></div>
        <div class="column-check-task" v-else>
            <div class="checkbox-non-clickable" :class="{active: task.done}">
                <icon-check />
            </div>
        </div>
        <div class="column-input" :class="{done: task.done}">
            <div class="text">
                {{ task.name }}
            </div>
        </div>
        <div class="column-input-date" v-if="(task.date || task.deadline) && extraInfo.includes('date') && fromStep != 'today_planning'">
            <div class="date" v-if="(task.type === 'task' && task.frequency != null) || extraInfo.includes('key_result')">
                {{ moment(task.deadline).format('DD MMM') }}
            </div>
            <div class="date" v-else>
                {{ moment(task.date).format('DD MMM') }}
            </div>
        </div>
        <div class="column-input-flag" v-if="extraInfo.includes('priority')">
            <div class="icon-flag">
                <icon-flag :class="{red: task.priority == 'urgent', yellow: task.priority == 'important', blue: task.priority == 'normal'}"/>
            </div>
        </div>
        <div class="column-input-avatar" v-if="extraInfo.includes('user')">
            <v-popover :disabled="$resize && $mq.below(780)" offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" v-if="task.responsible">
                <img class="avatar" :src="task.responsible.avatar"/>

                <template slot="popover">
                    <div class="title">{{ task.responsible.name }}</div>
                </template>
            </v-popover>

            <div class="user-circle" v-else>
                <icon-user />
            </div>
        </div>
        <div class="column-icon" v-if="extraInfo.includes('key_result')">
            <div class="icon-box">
                <icon-key-result />
            </div>
        </div>
    </div>
</template>

<script>
import IconFlag from '@/components/Icons/Flag'
import IconCheck from '@/components/Icons/Check'
import IconKeyResult from '@/components/Icons/KeyResult'
import IconUser from '@/components/Icons/User'
import IconRepeat from '@/components/Icons/Repeat'

export default {
    components: {
        IconCheck,
        IconFlag,
        IconRepeat,
        IconKeyResult,
        IconUser
    },
    props: {
        task: Object,
        extraInfo: Array,
        fromStep: String
    },
    computed: {
        promise() {
            return this.dataPromises[this.userId];
        },
    },
}
</script>
